.outlet {
    font-family: 'Open Sans', sans-serif;
    background: #FAFAFA;
    border-left: 1px solid #DADADA;
    /* width: 85vw; */

}
.scrollbar-green::-webkit-scrollbar {
    width: 10px;
  }
  
  .scrollbar-green::-webkit-scrollbar-thumb {
    background-color: green;
  }
  .index{
    z-index: 999 !important;
  }
  .index p{
    line-height: 1.5;
  }
  body.no-scroll {
    overflow: hidden;
  }
  
  /* Off-canvas scrollbar styling */
  .off-canvas-content {
    height: 100%;
    overflow-y: auto;
  }
  
  .off-canvas-content::-webkit-scrollbar {
    width: 8px;
  }
  
  .off-canvas-content::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }
  
  .off-canvas-content::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 8px;
    bottom:2px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }