.createoffer{
    width: 100%;
    border-left: 1px solid #DADADA;
    
}
.offer-text{
    color: #5F5C5C;
    font-size: 18px;
}
.offer{
    z-index: 999 !important;
}
