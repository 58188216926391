
.slider-wrapper {
    position: relative;
    background-color: rgb(44, 167, 33);  
    /* Tailwind green-500 */
    padding: 0.5rem 0;
}
.slider-section{
    background-color: rgb(44, 167, 33);  
}
.slider1{
    width: 100%;
    display: flex !important;
    justify-content: space-around;
}
.slider-wrapper .slider img {
    height: 7rem;
    margin-right: 1rem;
}
.slider-wrapper .slider button {
    background-color: white;
    color: black;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
}

.slider-wrapper .close-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
}
.slick-dots {
    position: absolute;
    color: white;
    bottom: 1px; /* Adjust as needed */
    width: 100%;
    display: flex !important;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .notifi{
    background-color: red !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
  .user-login{
    z-index: 999;
  }


