@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

.trackOffer {
    font-family: 'Open Sans', sans-serif;
    background: #FAFAFA;
    border-left: 1px solid #DADADA;
    /* width: 80vw; */
    height: 100vh;

}


.divider{
    color: #DADADA;
}

.offer_headings:hover{
    cursor: pointer;
}

.track_decoration, .createOffer_decoration, .location_decoration{
    position: relative;
    color: #2CA721;
}

.track_decoration::after, .createOffer_decoration::after, .location_decoration::after{
    content: '';
    position: absolute;
    bottom: -9px; /* Adjust this to position the underline on the horizontal line */
    left: 0;
    width: 100%;
    height: 3px; 
    background-color: #2CA721;
}

.trackOffer_btns button{
    /* width: 12%; */
    font-size: 15px;
    background: #ffffff;
    color: #000000;
    font-weight: 400;
    border: 1px solid #2CA721;
}

.trackOffer_btns button.active-btn, .normalOffer .right .active{
    background: #2CA721;
    color: #ffffff;
    font-weight: 700;
}

.normalOffer{
    border: 2px solid #2CA721;
}


.normalOffer .right button, .normalOffer .date, .offerInfo .offer_boxes .count_head{
    font-size: 14px;
}

.normalOffer .right .new_user{
    background: #D70A0A;
}

.offerInfo{
    border: 1px solid #DADADA;
}

.offerInfo .offer_boxes .box{
    border: 1px solid #2CA721;
    width: 22%;
}

.offerInfo .offer_boxes .count{
    font-size: 18px;
}

.offerInfo .offerDetails button{
    border: 1px solid #2CA721;
    color: #D70A0A;
    width: 97%;
}

.offerInfo .offerDetails p, .stopOffer p{
    font-size: 14px;
}

.stopOffer{
    width: 27%;
    border: 1px solid #DADADA;
    line-height: 1.5rem;
}

.exclamation{
    color: #D70A0A;
    font-size: 26px;
}
