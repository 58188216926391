.payment,
.orderHistory {
    width: 100%;
    height: 100vh;
}

.payment-headings:hover, .orderHistory .payout-arrow {
    cursor: pointer;
}

.payment-btns, .orderHistory-btns {
    color: #DADADA;
}

.payment-btn, .orderHistory-btn {
    color: #2CA721;
    position: relative;
}

.payment-btn::after, .orderHistory-btn::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #2CA721;
}

.past-cycle button{
    border-color: #2CA721;
}

.payouts {
    background: #f3f3f3;
}

.payouts .report {
    font-size: 15px;
}

.payouts table thead,
.payouts .icon {
    color: #aaaeb1;
}

/* .payouts table .estimation,
.payouts button {
    color: #2CA721;
} */

.payouts .retry-btn {
    background: #2CA721;
    color: #ffffff !important;
}

.orderHistory .payout-arrow {
    font-size: 20px;
}

.downloadPop{
    width: 30%;
    height: 50%;
    border: 1px solid #DADADA;
}


/* Add your CSS here */

/* Example styles */
.payouts, .order-history {
    padding: 20px;
  }
  
  .table, .content {
    width: 100%;
  }
  
  button {
    cursor: pointer;
  }
  

  
  button.bg-gray-200 {
    background-color: #e2e8f0;
  }
  

  
  .downloadPop {
    width: 400px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .calendar {
    margin-top: 20px;
  }
  
  .radio-group label {
    display: block;
    margin-bottom: 10px;
  }
  