
.calender{
    border: 2px solid #2CA721;
    cursor: pointer;
    display: flex;
    padding: 15px;
    border-radius: 10px;
}


.filter{
    display: flex;
}
.csv{
    border: 2px solid #2CA721;
    cursor: pointer;
    display: flex;
    padding: 15px;
    border-radius: 10px;
}
.correct{
    display: flex !important;
    justify-content: space-between;
    gap: 20px;
}

.custom-calendar .rdrCalendarWrapper {
    border: 1px solid #2ca721; /* Green border */
  }
 

  /* .custom-calendar .rdrDayHovered span,
  .custom-calendar .rdrSelected span {
    background-color: #2ca721 !important; /* Green background for selected/hovered days */
  /* } */
   



